import React from "react";

export const Inicio = () => {
  return (
    <div className='containerInicio'>
      <div className='containerTwo'>
        <div>
          <h1>Catálogo de Productos</h1>
        </div>
        <img src='/images/logo02.png' alt='' className='imagenInicio' />
      </div>
    </div>
  );
};
